<style lang="scss">
.copy-input {
  border: none;
}
</style>
<!--eslint-disable-->
<template>
  <div class="users-page">
    <b-card class="" title="Manage Keys">
      <b-card-text>This page allows you to create and manage keys for the MDM Electron system.</b-card-text>
    </b-card>

    <b-card no-body class="mb-0 ">

      <div class="m-2" v-if="$can('Create', 'Keys') && $can('Manage', 'All')">
        <b-row>
          <b-col cols="12" md="6"
                 class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <b-button @click="$bvModal.show('modal-add-key')" variant="primary">Create Key</b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="data-table" v-if="keys.length > 0"
        hover
        :items="keys"
        :fields="headers"
        :current-page="currentPage"
        :per-page="0"
      >
        <template #cell(policy_id)="data">
          {{ policyName(data.item.policy_id) }}
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | formatDateTime }}
        </template>

        <template #cell(uid)="data">
          <input class="copy-input mr-1" :value="data.item.uid" :id="data.item.uid" type="text"><i
          @click="copyKey(data.item.uid)" class="fas fa-copy text-success"></i>
        </template>

        <template #cell(actions)="data">
          <b-dropdown class="m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <b-dropdown-item-button class="full-nested-btn" v-if="$can('Update', 'Keys')"
                                    @click="showKeyModal(data.item, 'modal-update-key')">
              Update key
            </b-dropdown-item-button>
            <b-dropdown-item-button class="full-nested-btn" v-if="$can('Delete', 'Keys')"
                                    @click="showKeyModal(data.item, 'modal-delete-key')">
              Delete key
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>

      <p class="ml-2 mt-1" v-if="!loading && keys.length === 0">No Keys Found</p>
    </b-card>

    <b-modal id="modal-add-key" title="Add new key" hide-footer>
      <manage-key key="add-key" :policies="policies" @refresh="refreshKeys()" @close="closeModals()"></manage-key>
    </b-modal>

    <b-modal id="modal-update-key" title="Update key" hide-footer>
      <manage-key key="update-key" :policies="policies" :existing-key="selectedKey" @refresh="refreshKeys()" @close="closeModals()"></manage-key>
    </b-modal>

    <b-modal id="modal-delete-key" title="Delete key" hide-footer>
      <delete-modal
        v-if="selectedKey"
        @close="closeModals()" @delete="deleteKey(selectedKey)"
        :subtitle="selectedKey.ip_ranges"
        title="Are you sure you wish to delete key with IP range"></delete-modal>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import KeysService from '@/services/KeysService'
import ManageKey from '@/views/keys/sections/ManageKey.vue'
import DeleteModal from '@/components/modals/DeleteModal.vue'
import EapiPolicyService from '@/services/EapiPolicyService';
import { mapState } from 'vuex';

export default {
  name: 'Keys',
  components: {
    ManageKey,
    DeleteModal,
  },
  props: {},
  data() {
    return {
      headers: [
        {
          key: 'uid',
          label: 'Key',
        },
        {
          key: 'label',
          label: 'Label',
        },
        {
          key: 'enabled',
          label: 'Enabled',
        },
        {
          key: 'ip_ranges',
          label: 'IP Ranges',
        },
        {
          key: 'policy_id',
          label: 'Policy',
        },
        {
          key: 'created_at',
          label: 'Created At',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      keys: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        search: '',
      },
      total_count: 0,
      loading: true,
      selectedKey: null,
      policies: [],
      policyFilter: {
        enterprise_uid: '',
        page_size: 100,
      },
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.currentUser,
    }),
  },
  mounted() {
    this.policyFilter.enterprise_uid = this.user.enterprise_id
    this.getKeys()
    this.getPolicies()
  },
  methods: {
    refreshKeys() {
      this.closeModals()
      this.getKeys()
    },
    closeModals() {
      this.$bvModal.hide('modal-add-key')
      this.$bvModal.hide('modal-delete-key')
      this.$bvModal.hide('modal-update-key')
    },
    getKeys() {
      KeysService.getKeys()
        .then(res => {
          this.keys = res.data
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not get keys, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    copyKey(key) {
      // This is only available in secure contexts. This is a replacement for execCommand below
      if (navigator?.clipboard) {
        navigator.clipboard.writeText(key)
      }

      const input = document.getElementById(`${key}`)
      input.select()
      document.execCommand('copy')
      this.$toast.success('Copied')
    },
    showKeyModal(key, modalName) {
      this.selectedKey = key;
      this.$bvModal.show(modalName);
    },
    deleteKey(key) {
      KeysService.deleteKey(key.uid)
        .then(() => {
          this.$toast.success(`Deleted key ${key.ip_ranges} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          })

          this.refreshKeys()
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not delete key, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
    },
    getPolicies() {
      // Check we have permission to get the policies
      if(!this.$can('Manage', 'All')) {
        return
      }
      EapiPolicyService.getPolicies(this.policyFilter).then(res => {
        this.policies = res.data.data
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get policies, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      })
    },
    policyName(policyId) {
      for (let i = 0; i < this.policies.length; i += 1) {
        if (this.policies[i].id === policyId) {
          return this.policies[i].name;
        }
      }
      return ''
    },
  },
}
</script>
