<style lang="scss"></style>

<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(manageKey)">
        <section class="mb-2">
          Keys are created using CIDR notation e.g 80.80.102.44/32.
        </section>
        <section class="mb-2">
          <div>
            <label>IP Range</label>
            <validation-provider
              v-slot="validationContext"
              name="Identifier"
              :rules="{ required: true }"
            >
              <b-form-input
                v-model="key.ip_ranges"
                :state="getValidationState(validationContext)" class="mb-1" placeholder="e.g. 0.0.0.0/0"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>

            <b-input-group class="mb-2 d-flex flex-column">
              <div>
                <label for="enterprise_uid-in">Policy</label>
                <b-input-group-append>
                  <model-list-select v-model="key.policy_id"
                                     :list="policies"
                                     option-value="id"
                                     option-text="name"
                                     placeholder="Select Policy"
                  />
                  <b-button @click="clearPoliciesFilter">Clear</b-button>
                </b-input-group-append>
              </div>
            </b-input-group>

            <label>Label</label>
            <br>
            <small>This is an optional tag to show where the key is being used</small>
            <b-form-input v-model="key.label" class="mb-1" placeholder="e.g. East Wing" />

            <b-form-checkbox v-model="key.enabled" name="check-button" switch>
              Key Enabled
            </b-form-checkbox>
          </div>
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
          <b-button v-if="!existingKey" variant="primary" type="submit">Add new key</b-button>
          <b-button v-else variant="primary" type="submit">Update key</b-button>
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import KeysService from '@/services/KeysService'
import { ModelListSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css';

export default {
  name: 'MangeKey',
  components: {
    ModelListSelect,
  },
  props: {
    existingKey: {
      type: Object,
      required: false,
      default: null,
    },
    policies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      key: {
        ip_ranges: '',
        enabled: true,
        label: '',
        policy_id: 0,
      },
    }
  },
  mounted() {
    if (this.existingKey) {
      Object.assign(this.key, this.existingKey);
    }
  },
  methods: {
    manageKey() {
      if (this.existingKey) {
        this.updateKey();
      } else {
        this.addKey();
      }
    },
    addKey() {
      KeysService.createKey(this.key)
        .then(() => {
          this.$toast.success('Added key Successfully', {
            toastClassName: ['toast-std', 'success-toast'],
          })

          this.$emit('refresh', true)
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not add key, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
    },
    updateKey() {
      KeysService.updateKey(this.existingKey.uid, this.key)
        .then(() => {
          this.$toast.success('Updated key Successfully', {
            toastClassName: ['toast-std', 'success-toast'],
          })

          this.$emit('refresh', true)
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not update key, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
    },
    clearPoliciesFilter() {
      this.key.policy_id = 0
    },
    getValidationState({
      dirty,
      validated,
      valid = null,
    }) {
      return dirty || validated ? valid : null
    },
  },
}
</script>
