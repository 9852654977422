import axios from '@axios';

const baseUrl = '/keys';

export default {
  getKeys() {
    return axios.get(`${baseUrl}`);
  },
  createKey(dto) {
    return axios.post(`${baseUrl}`, dto);
  },
  updateKey(id, dto) {
    return axios.put(`${baseUrl}/${id}`, dto);
  },
  deleteKey(id) {
    return axios.delete(`${baseUrl}/${id}`);
  },
};
