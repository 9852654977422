import axios from '@axios';
import HelperService from '@/services/HelperService';

const baseUrl = '/admin/eapi-policies';

export default {
  getPolicies(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  getPolicy(id) {
    return axios.get(`${baseUrl}/${id}`);
  },
  createPolicy(policy) {
    return axios.post(`${baseUrl}`, policy);
  },
  updatePolicy(id, policy) {
    return axios.put(`${baseUrl}/${id}`, policy);
  },
  deletePolicy(id) {
    return axios.delete(`${baseUrl}/${id}`);
  },
  getPolicyFilenames() {
    return axios.get(`${baseUrl}/filenames`)
  },
  bulkUpdatePolicyFilenames(updatePolicies) {
    return axios.put(`${baseUrl}/filenames`, updatePolicies)
  },
};
